/// <reference path="PrivacyStatement.d.ts" />
var Authentication;
(function (Authentication) {
    var PrivacyStatementDialogHandler = /** @class */ (function () {
        function PrivacyStatementDialogHandler(title, acceptedPrivacyStatement) {
            var _this = this;
            this._isOpen = false;
            this._acceptedPrivacyStatement = false;
            this.UserShouldChangePassword = ko.observable(false);
            this.SetTitle = function (title) {
                _this._title = title;
            };
            this._title = title;
            this._acceptedPrivacyStatement = (acceptedPrivacyStatement == true);
            _privacyStatementInstance = this;
        }
        Object.defineProperty(PrivacyStatementDialogHandler.prototype, "IsOpen", {
            get: function () { return this._isOpen; },
            enumerable: true,
            configurable: true
        });
        PrivacyStatementDialogHandler.prototype.ShowDialog = function (fromLogin) {
            var _this = this;
            this._isOpen = true;
            var loadingHtml = this.GetLoadingHtml();
            this._dialog = new BootstrapDialog({
                cssClass: 'private-statement-modal',
                size: Common.DialogSize.Normal,
                message: loadingHtml,
                closable: this._acceptedPrivacyStatement,
                title: '<i class="fa fa-user-secret fa-lg"></i>' + this._title,
                animate: false,
                onhide: function (dialog) {
                    _this._isOpen = false;
                },
                onshown: function (dialog) {
                    //fix: bootstrap modal denies focus to kendo grid filter input fields
                    $(document).off('focusin.modal');
                    dialog.$modal.removeAttr("tabindex");
                }
            });
            this._dialog.realize();
            this._dialog.$modal.removeAttr('aria-hidden');
            Ajax.Get('/Account/PrivacyStatement/Dialog', { ShowFromLogin: fromLogin }).done(function (html) {
                var body = _this._dialog.getModalBody();
                var wrapper = new Common.HtmlWrapper(body);
                wrapper.LoadHtml(html);
                _this._dialog.open();
            }).fail(function () {
                _this._isOpen = false;
            });
        };
        PrivacyStatementDialogHandler.prototype.GetLoadingHtml = function () {
            var loadingHtml = $('<div style="height: 100px;"></div>');
            var loadingPanel = new Common.LoadingPanel(loadingHtml);
            loadingPanel.ShowPanel();
            return loadingHtml;
        };
        PrivacyStatementDialogHandler.prototype.CloseDialog = function () {
            this._isOpen = false;
            this._dialog.close();
        };
        Object.defineProperty(PrivacyStatementDialogHandler, "Instance", {
            get: function () {
                return _privacyStatementInstance;
            },
            enumerable: true,
            configurable: true
        });
        return PrivacyStatementDialogHandler;
    }());
    Authentication.PrivacyStatementDialogHandler = PrivacyStatementDialogHandler;
})(Authentication || (Authentication = {}));

var Authentication;
(function (Authentication) {
    var PanelType = /** @class */ (function () {
        function PanelType() {
        }
        Object.defineProperty(PanelType, "Login", {
            get: function () { return 0; },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(PanelType, "ForgotPassword", {
            get: function () { return 1; },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(PanelType, "ForgotPasswordSuccess", {
            get: function () { return 2; },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(PanelType, "ResetPassword", {
            get: function () { return 3; },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(PanelType, "ResetPasswordSuccess", {
            get: function () { return 4; },
            enumerable: true,
            configurable: true
        });
        return PanelType;
    }());
    Authentication.PanelType = PanelType;
})(Authentication || (Authentication = {}));

var Authentication;
(function (Authentication) {
    var ForgotPasswordViewModel = /** @class */ (function () {
        function ForgotPasswordViewModel(form) {
            var _this = this;
            this.Submitting = ko.observable(false);
            this.SubmitForm = function () {
                if (_this.Submitting()) {
                    return;
                }
                if (_this._form.valid()) {
                    var url = _this._form.attr('action');
                    var data = _this._form.serialize();
                    _this.Submitting(true);
                    _this._form.submit();
                }
            };
            this._form = form;
        }
        return ForgotPasswordViewModel;
    }());
    Authentication.ForgotPasswordViewModel = ForgotPasswordViewModel;
})(Authentication || (Authentication = {}));

var Authentication;
(function (Authentication) {
    var ResetPasswordViewModel = /** @class */ (function () {
        function ResetPasswordViewModel(form, passwordStrength, validationHandler) {
            var _this = this;
            this.Submitting = ko.observable(false);
            this.ShowErrorMessage = ko.observable(false);
            this.SubmitForm = function () {
                if (_this.Submitting()) {
                    return;
                }
                if (_this._form.valid()) {
                    _this.Submitting(true);
                    Ajax.Get('/Account/AntiForgeryToken').done(function (html) {
                        var token = $(html).find('[name="__RequestVerificationToken"]').val();
                        _this._form.find('[name="__RequestVerificationToken"]').val(token);
                        _this._form.submit();
                    });
                }
            };
            this._form = form;
            this.PasswordStrengthViewModel = passwordStrength;
            validationHandler.InitializeFormValidation(form);
        }
        ResetPasswordViewModel.prototype.Dispose = function () {
            this.PasswordStrengthViewModel.Dispose();
        };
        return ResetPasswordViewModel;
    }());
    Authentication.ResetPasswordViewModel = ResetPasswordViewModel;
})(Authentication || (Authentication = {}));

/// <reference path="../Common/Common.shim.ts" />
var Authentication;
(function (Authentication) {
    var LoginViewModel = /** @class */ (function () {
        function LoginViewModel(form, sessionStorage, manager, passwordChecker, validationHandler, authenticatorPinIsNeeded) {
            var _this = this;
            this.IsMobile = ko.pureComputed(function () {
                if (navigator.IsMobile()) {
                    return 'true';
                }
                return 'false';
            });
            this.CultureCssClass = ko.pureComputed(function () {
                return _this.Culture().toLocaleLowerCase();
            });
            this.TimezoneIdentifier = ko.observable('');
            this.UserName = ko.observable(undefined);
            this.Password = ko.observable(undefined);
            this.Company = ko.observable('base');
            this.PasswordStrength = ko.observable(0);
            this.SingleSignOn = ko.observable(false);
            this.MfaAuthenticationPin = ko.observable('');
            this.AuthenticatorPinIsNeeded = ko.observable(false);
            this.Initialized = function () {
                if (_this.AuthenticatorPinIsNeeded()) {
                    $('#MfaAuthenticationPin').focus();
                }
                else {
                    $('#UserName').trigger('focus');
                }
            };
            this.SelectLanguage = function (item, event) {
                var $element = $(event.target);
                $('#Culture').val($element.data('culture'));
                _this.Culture($element.data('culture'));
            };
            this.ToggleSingleSignOn = function () {
                _this.SingleSignOn(!_this.SingleSignOn());
                if (_this.SingleSignOn()) {
                    _this.Password('');
                }
                _this._sessionStorage.Store(StorageKeys.Login.SingleSignOn, _this.SingleSignOn());
                _this._validationHandler.ToggleSingleSignOnValidation(_this.SingleSignOn());
            };
            this.SubmitForm = function () {
                if (!_this._form.valid()) {
                    return;
                }
                if ($('input[name="UserName"]').val() == "") {
                    $('input[name="UserName"]').focus();
                    return;
                }
                if ($('input[name="Password"]').val() == "" && !_this.SingleSignOn()) {
                    $('input[name="Password"]').focus();
                    return;
                }
                if (_this.Company() == "") {
                    $('input[name="Company"]').focus();
                    return;
                }
                if ($('#login #LoginButton .btn').attr('disabled') === 'disabled') {
                    return;
                }
                $('#login #LoginButton i').removeClass('hide');
                $('#login #LoginButton .btn').attr('disabled', 'disabled');
                var password = $('#Password').val().toString();
                _this.PasswordStrength(_this._passwordChecker.checkPassStrength(password));
                var data = _this._form.serialize();
                Ajax.Post('/Account/Login', data).done(function (json) {
                    if (json.ShowAuthenticatorPin) {
                        _this.AuthenticatorPinIsNeeded(true);
                        $('#login #LoginButton i').addClass('hide');
                        $('#login #LoginButton .btn').removeAttr('disabled');
                        $('#MfaAuthenticationPin').focus();
                        return;
                    }
                    if (json.Html) {
                        $('body').html(json.Html);
                        return;
                    }
                    if (json.Redirect) {
                        window.location.href = json.Redirect;
                        return;
                    }
                });
            };
            this._sessionStorage = sessionStorage;
            this.AuthenticatorPinIsNeeded(authenticatorPinIsNeeded);
            this._passwordChecker = passwordChecker;
            manager.ClearMessages();
            this.Culture = ko.observable($('#Culture').val());
            $('input[name="Password"]').attr('autocomplete', 'off');
            this._form = form;
            this.BindEvents();
            this.TimezoneIdentifier(moment.tz.guess());
            $('#BrowserType').val(bowser.name);
            this._validationHandler = validationHandler;
            this._validationHandler.Initialize(this);
            if (this._sessionStorage.Get(StorageKeys.Login.SingleSignOn) == 'true') {
                this.SingleSignOn(true);
                this._validationHandler.ToggleSingleSignOnValidation(this.SingleSignOn());
            }
        }
        LoginViewModel.prototype.BindEvents = function () {
            var _this = this;
            this._form.keypress(function (event) {
                if ($(event.target).is('#UserName') && event.keyCode == KeyCode.Enter) {
                    if (_this.SingleSignOn()) {
                        _this.SubmitForm();
                    }
                    else {
                        $('input[name="Password"]').focus();
                    }
                }
                if (!$(event.target).is('#UserName') && event.keyCode == KeyCode.Enter) {
                    _this.SubmitForm();
                }
            });
            $('.dropdown-toggle').click(function (ev) {
                ev.stopPropagation();
                $(this).parent().toggleClass('open');
            });
        };
        return LoginViewModel;
    }());
    Authentication.LoginViewModel = LoginViewModel;
})(Authentication || (Authentication = {}));

var Authentication;
(function (Authentication) {
    var LoginTranslationsViewModel = /** @class */ (function () {
        function LoginTranslationsViewModel() {
            // login 
            this.UserNamePlaceHolder = ko.observable('');
            this.PasswordPlaceHolder = ko.observable('');
            this.RememberMeLabel = ko.observable('');
            this.ForgotPasswordLabel = ko.observable('');
            this.LoginButtonText = ko.observable('');
            // forgot password
            this.ForgotPasswordInfo = ko.observable('');
            this.ForgotPasswordPlaceHolder = ko.observable('');
            this.ForgotPasswordButtonText = ko.observable('');
            this.ForgotPasswordBackButtonText = ko.observable('');
            // browser support
            this.BrowserSupportBannerMessage = ko.observable('');
        }
        return LoginTranslationsViewModel;
    }());
    Authentication.LoginTranslationsViewModel = LoginTranslationsViewModel;
})(Authentication || (Authentication = {}));

var Authentication;
(function (Authentication) {
    var LoginOverviewViewModel = /** @class */ (function () {
        function LoginOverviewViewModel(loginViewModel, forgotPasswordViewModel, resetPasswordViewModel, supportedBrowsersViewModel, translationsViewModel, privacyStatementDialog, hasLoggedIn, acceptedPrivacyStatement) {
            var _this = this;
            this._hasLoggedIn = false;
            this._acceptedPrivacyStatement = false;
            this.ActivePanel = ko.observable(Authentication.PanelType.Login);
            this.ShowError = ko.observable(true);
            this.ShouldShowLoginPanel = ko.pureComputed(function () { return _this.ActivePanel() == Authentication.PanelType.Login; });
            this.ShouldShowForgotPasswordPanel = ko.pureComputed(function () { return _this.ActivePanel() == Authentication.PanelType.ForgotPassword; });
            this.ShouldShowForgotPasswordSuccessPanel = ko.pureComputed(function () { return _this.ActivePanel() == Authentication.PanelType.ForgotPasswordSuccess; });
            this.ShouldShowResetPasswordPanel = ko.pureComputed(function () { return _this.ActivePanel() == Authentication.PanelType.ResetPassword; });
            this.ShouldShowResetPasswordSuccessPanel = ko.pureComputed(function () { return _this.ActivePanel() == Authentication.PanelType.ResetPasswordSuccess; });
            this.ShouldShowBrowserSupportBanner = ko.observable(false);
            this._onStateChanged = new DefaultEvent();
            this.TranslateFormElements = function (culture) {
                $.ajax({
                    url: '/Account/Culture',
                    data: {
                        culture: culture,
                        __RequestVerificationToken: window.AntiForgeryToken
                    },
                    type: 'POST'
                });
                _this.ShowError(false);
                // login
                _this.Translations.UserNamePlaceHolder(LoginTranslationHandler.Instance.GetTranslation('Account_Login_UserName_PlaceHolder', culture));
                _this.Translations.PasswordPlaceHolder(LoginTranslationHandler.Instance.GetTranslation('Account_Login_Password_PlaceHolder', culture));
                _this.Translations.RememberMeLabel(LoginTranslationHandler.Instance.GetTranslation('Account_Login_RememberMe_Label', culture));
                _this.Translations.ForgotPasswordLabel(LoginTranslationHandler.Instance.GetTranslation('Account_Login_ForgotPassword_Label', culture));
                // forgot password
                _this.Translations.ForgotPasswordInfo(LoginTranslationHandler.Instance.GetTranslation('Account_Login_ForgotPassword_Info', culture));
                _this.Translations.ForgotPasswordPlaceHolder(LoginTranslationHandler.Instance.GetTranslation('Account_Login_ForgotPassword_PlaceHolder', culture));
                _this.Translations.ForgotPasswordButtonText(LoginTranslationHandler.Instance.GetTranslation('Account_Login_ForgotPassword_ButtonText', culture));
                _this.Translations.ForgotPasswordBackButtonText(LoginTranslationHandler.Instance.GetTranslation('Account_Login_ForgotPassword_BackButtonText', culture));
                // browser support
                _this.Translations.BrowserSupportBannerMessage(LoginTranslationHandler.Instance.GetTranslation('Account_Login_BrowserSupport_BannerMessage', culture));
                _this.AddBrowserSupportBannerBindings();
            };
            this._translationHandlerReady = function () {
                if (_this._hasLoggedIn && !_this._acceptedPrivacyStatement) {
                    var privacyDialogTitle = LoginTranslationHandler.Instance.GetTranslation('Account_Login_ModelError_PrivacyStatement_Title', _this.LoginViewModel.Culture());
                    _this._privacyStatementDialog.SetTitle(privacyDialogTitle);
                    _this._privacyStatementDialog.ShowDialog(true);
                }
                _this._privacyStatementDialog.UserShouldChangePassword.subscribe(function (userShouldChangePassword) {
                    _this.PushState({ panel: Authentication.PanelType.ResetPassword }, '/Account/Password/Reset/');
                });
                _this.CheckBrowser();
            };
            this.PushState = function (data, url) {
                history.pushState(data, url);
                _this._onStateChanged.trigger(data);
            };
            this.ShowLoginPanel = function () {
                _this.PushState({ panel: Authentication.PanelType.Login }, '/Account/Login/');
            };
            this.ShowForgotPasswordPanel = function () {
                if (Browser.IsSupported) {
                    var url = '/Account/Password/Forgot/?company={0}'.Format(_this.LoginViewModel.Company());
                    _this.PushState({ panel: Authentication.PanelType.ForgotPassword }, url);
                }
            };
            this.ShowResetPasswordPanel = function () {
                _this.PushState({ panel: Authentication.PanelType.ResetPassword }, '/Account/Password/Reset/');
            };
            this._privacyStatementDialog = privacyStatementDialog;
            this._hasLoggedIn = hasLoggedIn;
            this._acceptedPrivacyStatement = acceptedPrivacyStatement;
            this.LoginViewModel = loginViewModel;
            this.ForgotPasswordViewModel = forgotPasswordViewModel;
            this.ResetPasswordViewModel = resetPasswordViewModel;
            this.SupportedBrowsersViewModel = supportedBrowsersViewModel;
            this.Translations = translationsViewModel;
            if (!Browser.IsSupported) {
                //disable login fields
                $(".login-container .fields #UserName").prop('disabled', true);
                $(".login-container .fields #Password").prop('disabled', true);
                $(".login-container .fields #Company").prop('disabled', true);
                $(".login-container .fields #RememberMe").prop('disabled', true);
                $(".login-container .fields button").prop('disabled', true);
                $('#login #LoginButton .btn').attr('disabled', 'disabled');
            }
            this._onStateChanged.on(function (data) {
                var panel = data.panel;
                if (panel) {
                    _this.ActivePanel(parseInt(data.panel));
                    return;
                }
                _this.ActivePanel(Authentication.PanelType.Login);
            });
            this.LoginViewModel.Culture.subscribe(this.TranslateFormElements);
            LoginTranslationHandler.Instance.ListLoaded.done(this._translationHandlerReady);
        }
        LoginOverviewViewModel.prototype.CheckBrowser = function () {
            var _this = this;
            if (!Browser.IsSupported) {
                this.ShouldShowBrowserSupportBanner(true);
                this.Translations.BrowserSupportBannerMessage.subscribe(function () {
                    _this.AddBrowserSupportBannerBindings();
                });
                var message = LoginTranslationHandler.Instance.GetTranslation('Account_Login_BrowserSupport_BannerMessage', this.LoginViewModel.Culture());
                this.Translations.BrowserSupportBannerMessage(message.replace("[version]", (bowser.name + ' ' + bowser.version)));
                $('.browser-support-banner').animate({
                    'margin-top': "+=100px"
                }, 1000);
            }
        };
        LoginOverviewViewModel.prototype.AddBrowserSupportBannerBindings = function () {
            var _this = this;
            setTimeout(function () {
                $(".browser-support-link").on("click", function (event) {
                    _this.SupportedBrowsersViewModel.ShowDialog(_this.LoginViewModel.Culture());
                });
            }, 5);
        };
        return LoginOverviewViewModel;
    }());
    Authentication.LoginOverviewViewModel = LoginOverviewViewModel;
})(Authentication || (Authentication = {}));

var Authentication;
(function (Authentication) {
    var SupportedBrowsersViewModel = /** @class */ (function () {
        function SupportedBrowsersViewModel() {
            this.ViewLoaded = $.Deferred();
        }
        SupportedBrowsersViewModel.prototype.ShowDialog = function (culture) {
            var _this = this;
            Ajax.Get('/Account/SupportedBrowsers').done(function (html) {
                _this.InitializeView($(html), culture);
            });
        };
        SupportedBrowsersViewModel.prototype.InitializeView = function (html, culture) {
            this._title = LoginTranslationHandler.Instance.GetTranslation('Account_Login_BrowserSupport_Dialog_Title', culture);
            if (Browser.OS === "windows") {
                html.find(".browser.ie").removeClass("hide");
                html.find(".browser.edge").removeClass("hide");
            }
            if (Browser.OS === "mac") {
                html.find(".browser.safari").removeClass("hide");
            }
            this._message = $(html.find('#message').html());
            this._dialog = this.InitializeDialog();
            this._dialog.realize();
            this._dialog.$modal.removeAttr('aria-hidden');
            this._dialog.getModalFooter().hide();
            this._dialog.open();
        };
        SupportedBrowsersViewModel.prototype.InitializeDialog = function () {
            var _this = this;
            return new BootstrapDialog({
                title: function (dialogRef) { return _this._title; },
                message: function (dialogRef) {
                    return _this._message;
                },
                animate: Browser.IsInternetExplorer ? false : true,
                cssClass: 'supported-browsers-dialog',
                onshown: function () {
                    _this.ViewLoaded.resolve($(_this._dialog.getModalBody()));
                },
                closable: true
            });
        };
        return SupportedBrowsersViewModel;
    }());
    Authentication.SupportedBrowsersViewModel = SupportedBrowsersViewModel;
})(Authentication || (Authentication = {}));

/// <reference path='../Infrastructure/Storage/Storage.shim.ts' />
/// <reference path='./Authentication.shim.ts' />
var Authentication;
(function (Authentication) {
    $(function () {
        var html = $('#login');
        var translationHandler = new LoginTranslationHandler();
        var passwordChecker = new Authentication.PasswordStrengthChecker();
        var passwordStrengthViewModel = new Authentication.PasswordStrengthViewModel(passwordChecker);
        var authenticatorPinIsNeeded = ($('#authenticator-pin-is-needed').val() == 'true');
        var loginViewModel = InitializeLoginViewModel(passwordChecker, translationHandler, authenticatorPinIsNeeded);
        var resetPasswordViewModel = new Authentication.ResetPasswordViewModel($('#reset-password-form'), passwordStrengthViewModel, new ValidationHandler());
        var forgotPasswordViewModel = new Authentication.ForgotPasswordViewModel($('#forgot-password-form'));
        var translationsViewModel = new Authentication.LoginTranslationsViewModel();
        var supportedBrowsersViewModel = new Authentication.SupportedBrowsersViewModel();
        var privacyStatementDialog = new Authentication.PrivacyStatementDialogHandler();
        var hasLoggedIn = ($('#accepted-privacystatement').length > 0);
        var acceptedPrivacyStatement = ($('#accepted-privacystatement').val() == 'true');
        var overviewModel = new Authentication.LoginOverviewViewModel(loginViewModel, forgotPasswordViewModel, resetPasswordViewModel, supportedBrowsersViewModel, translationsViewModel, privacyStatementDialog, hasLoggedIn, acceptedPrivacyStatement);
        ko.tryApplyBindings(overviewModel, html.get(0));
        loginViewModel.Initialized();
    });
    function InitializeLoginViewModel(passwordChecker, translationHandler, authenticatorPinIsNeeded) {
        var storage = new SessionStorage();
        try {
            storage.Store(StorageKeys.Login.IsApplicationInitialized, false);
        }
        catch (err) {
            LogError(err);
        }
        var manager = new Notifications.NotificationsSessionStoreManager(storage);
        var loginForm = $('#login-form');
        translationHandler.ListLoaded.done(function () {
            var elements = loginForm.find('input[type="text"][data-val-required],input[type="password"][data-val-required]');
            elements.each(function (index, element) {
                var $element = $(element);
                var translation = translationHandler.GetTranslation($element.attr('data-val-required'), $('#Culture').val());
                $element.data('val-required', translation);
                $element.attr('data-val-required', translation);
                $element.prop('data-val-required', translation);
            });
            $('#login-form').removeData('validator');
            $('#login-form').removeData('unobtrusiveValidation');
            $.validator.unobtrusive.parse($('#login-form'));
        });
        var handler = new LoginValidationHandler(ValidationHandler.Instance);
        return new Authentication.LoginViewModel(loginForm, storage, manager, passwordChecker, handler, authenticatorPinIsNeeded);
    }
})(Authentication || (Authentication = {}));
